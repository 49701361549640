






























































































































































































































































































































import { Component, Watch } from "vue-property-decorator";
import PageOptions from "../../config/PageOptions";
import moment from "moment-business-days";
import axios, { AxiosPromise } from "axios";
import { ShoppingCartModel, CartProductModel } from "@/models/shoppingCartModel";
import { addressModel } from "@/models/addressModel";
import eventHub from "@/eventHub";
import { sisasPlaceOrderResult } from "@/models/sisasPlaceOrderResult";
import { pagedList } from "@/models/pagedList";
import { productModel } from "@/models/productModel";
import _ from "lodash";
import { sisasListiniModel } from "@/models/sisasPimListiniModel";
import { CustomerInfoModel } from "@/models/customerModels";
import baseUserContextProductComponent from "@/components/baseUserContextProductComponent";
import { UserLoggedInfoModel } from "@/models/userModels";

enum tablist {
	carrello = "carrello",
	spedizione = "spedizione",
	informazioni = "informazioni",
	riepilogo = "riepilogo"
}
@Component({})
export default class checkoutPage extends baseUserContextProductComponent {
	expressCost: number = 53;
	standardCost: number = 26;
	standardDays: number = 7;
	expressDays: number = 4;
	showOpzioniConsegna: boolean = true;
	showInformazioni: boolean = true;
	showVerificaOrdine: boolean = true;
	customerReferentCode: string = null;

	get customerName(): string {
		var instance = this;
		var c = instance.getCurrentCustomer;
		if (c == null || c.company == null || c.company == "") return "-";
		return c.company;
	}
	get customerEmail(): string {
		var instance = this;
		var c = instance.getCurrentCustomer;
		if (c == null || c.email == null || c.email == "") return "";
		return c.email;
	}
	get customerInfo(): string {
		var instance = this;
		var c = instance.getCurrentCustomer;
		if (c != null && c.vatCode != null && c.vatCode != "") return c.vatCode;
		else if (c != null && c.taxCode != null && c.taxCode != "") return c.taxCode;
		else return "-";
	}
	currentTab: string = tablist.spedizione.toString();
	onTabClick(tab: string) {
		var instance = this;
		if (tab == tablist.carrello.toString()) {
			instance.$router.push({ path: `/cart` });
		} else instance.currentTab = tab;
	}
	isTabActive(tab: string) {
		var instance = this;
		return instance.currentTab == tab;
	}

	showNoteStato: boolean = false;
	@Watch("getCurrentCustomer", { deep: true })
	onCurrentCustomerChange(newValue, oldValue) {
		this.fetchListini();
	}
	@Watch("cart", { deep: true })
	onCartChange(newValue, oldValue) {
		this.fetchListini();
	}
	get productsOnCart(): number {
		if (this.cart == null || this.cart.products == null) return null;

		return _.uniq(_.map(this.cart.products, "sku")).length;
	}
	// getUser!: UserLoggedInfoModel;
	pageOptions = PageOptions;

	isLoading: boolean = false;
	showAddressModal: boolean = false;
	note: string = null;
	//referentOrderStatusName: string = null;
	get referentOrderStatusName(): string {
		var field = "referentOrderStatusName";
		return localStorage.getItem(`b2b_${field}_${this.getCurrentCustomerId}`) || this.customerName;
	}
	set referentOrderStatusName(value: string) {
		var field = "referentOrderStatusName";
		localStorage.setItem(`b2b_${field}_${this.getCurrentCustomerId}`, value);
	}
	//referentOrderStatusEmail: string = null;
	get referentOrderStatusEmail(): string {
		var field = "referentOrderStatusEmail";
		return localStorage.getItem(`b2b_${field}_${this.getCurrentCustomerId}`) || this.customerEmail;
	}
	set referentOrderStatusEmail(value: string) {
		var field = "referentOrderStatusEmail";
		localStorage.setItem(`b2b_${field}_${this.getCurrentCustomerId}`, value);
	}
	get referentDDTName(): string {
		var field = "referentDDTName";
		return localStorage.getItem(`b2b_${field}_${this.getCurrentCustomerId}`) || this.customerName;
	}
	set referentDDTName(value: string) {
		var field = "referentDDTName";
		localStorage.setItem(`b2b_${field}_${this.getCurrentCustomerId}`, value);
	}
	get referentDDTEmail(): string {
		var field = "referentDDTEmail";
		return localStorage.getItem(`b2b_${field}_${this.getCurrentCustomerId}`) || this.customerEmail;
	}
	set referentDDTEmail(value: string) {
		var field = "referentDDTEmail";
		localStorage.setItem(`b2b_${field}_${this.getCurrentCustomerId}`, value);
	}
	get referentFatturaName(): string {
		var field = "referentFatturaName";
		return localStorage.getItem(`b2b_${field}_${this.getCurrentCustomerId}`) || this.customerName;
	}
	set referentFatturaName(value: string) {
		var field = "referentFatturaName";
		localStorage.setItem(`b2b_${field}_${this.getCurrentCustomerId}`, value);
	}
	get referentFatturaEmail(): string {
		var field = "referentFatturaEmail";
		return localStorage.getItem(`b2b_${field}_${this.getCurrentCustomerId}`) || this.customerEmail;
	}
	set referentFatturaEmail(value: string) {
		var field = "referentFatturaEmail";
		localStorage.setItem(`b2b_${field}_${this.getCurrentCustomerId}`, value);
	}
	shippingsType: string = null;
	myreceveOrderStatus: boolean = true;
	get receveOrderStatus(): boolean {
		var field = "receveOrderStatus";
		var value = localStorage.getItem(`b2b_${field}_${this.getCurrentCustomerId}`);

		if (value == null || value == "true") this.myreceveOrderStatus = true;
		else this.myreceveOrderStatus = false;
		return this.myreceveOrderStatus;
	}
	set receveOrderStatus(value: boolean) {
		var field = "receveOrderStatus";
		localStorage.setItem(`b2b_${field}_${this.getCurrentCustomerId}`, value ? "true" : "false");
		this.myreceveOrderStatus = value;
	}
	myreceveDDT: boolean = true;
	get receveDDT(): boolean {
		var field = "receveDDT";
		var value = localStorage.getItem(`b2b_${field}_${this.getCurrentCustomerId}`);

		if (value == null || value == "true") this.myreceveDDT = true;
		else this.myreceveDDT = false;
		return this.myreceveDDT;
	}
	set receveDDT(value: boolean) {
		var field = "receveDDT";
		localStorage.setItem(`b2b_${field}_${this.getCurrentCustomerId}`, value ? "true" : "false");
		this.myreceveDDT = value;
	}
	myreceveFattura: boolean = true;
	get receveFattura(): boolean {
		var field = "receveFattura";
		var value = localStorage.getItem(`b2b_${field}_${this.getCurrentCustomerId}`);

		if (value == null || value == "true") this.myreceveFattura = true;
		else this.myreceveFattura = false;
		return this.myreceveFattura;
	}
	set receveFattura(value: boolean) {
		var field = "receveFattura";
		localStorage.setItem(`b2b_${field}_${this.getCurrentCustomerId}`, value ? "true" : "false");
		this.myreceveFattura = value;
	}
	deliveryDate: Date | string | null = null;
	isInEditNote: boolean = false;
	isSendingNote: boolean = false;
	isProcessingOrder: boolean = false;
	isOrderCreated: boolean = false;
	isLoadingLisini: boolean = false;
	listini: sisasListiniModel[] = [];

	showCustomerModal: boolean = false;
	isChangingAddress: boolean = false;

	get showCustomerDescription(): boolean {
		return this.isUserB2BAdmim || this.isUserRefEmployee || this.isUserEmployee;
	}

	onSelectCustomer(customer: CustomerInfoModel) {
		var instance = this;
		instance.setCurrentCustomer(customer);
		instance.showCustomerModal = false;
	}
	get cart(): ShoppingCartModel {
		return this.getCurrentCart;
	}
	get isCurrentCustomerSelected(): boolean {
		return this.getCurrentCustomer != null;
	}
	// get products() {
	//   if (this.cart == null || this.cart.products == null) return [];

	//   var products = _.groupBy(this.cart.products, "product.id");
	//   return products == null
	//     ? []
	//     : _.mapKeys(products, (key, value) => {
	//         return value;
	//       });
	// }
	get skus() {
		if (this.cart == null || this.cart.products == null) return [];
		//return _.uniq(_.map(this.cart.products, "sku"));
		var result = [];
		var products = JSON.parse(JSON.stringify(this.cart.products));
		products
			.sort((a, b) => (a.createDate > b.createDate ? 1 : -1))
			.forEach(x => {
				if (result.indexOf(x.sku) == -1) result.push(x.sku);
			});
		return result;
	}
	get addresses(): addressModel[] {
		return this.getCurrentCustomer == null ? [] : this.getCurrentCustomer.shippingAddresses;
	}
	get cartTotal(): number {
		if (this.cart == null || this.cart.products == null) return 0;
		return this.cart.products.reduce(function(total, item) {
			return total + item.rowTotal;
		}, 0);
	}
	get shippingAddress(): addressModel {
		return this.cart == null && this.cart.shipping == null ? null : this.cart.shipping[0];
	}
	get isShippingAddressSelected(): boolean {
		return this.shippingAddress != null;
	}
	getProducts(sku: string): CartProductModel[] {
		if (this.cart == null || this.cart.products == null) return [];
		var products = this.cart.products.filter(x => x.sku == sku);
		return products == null ? [] : products;
	}
	getListini(sku: string): sisasListiniModel[] {
		if (this.listini == null) return [];
		var listini = this.listini.filter(x => x.articolo == sku);
		if (listini == null) return [];
		return listini;
	}
	// onQuickAddToCart(product: productModel) {
	//   var instance = this;
	//   axios
	//     .post<ShoppingCartModel>(`cart/quickaddproduct`, {
	//       customerId: instance.getCurrentCustomerId,
	//       productId: product.id
	//     })
	//     .then(res => {
	//       if (res.data) {
	//         instance.setCurrentCart(res.data);
	//         instance.$notify({
	//           title: "",
	//           text: "Il prodotto è stato aggiunto al carrello",
	//           type: "success",
	//           group: "msg"
	//         });
	//         this.fetchListini();
	//       }
	//     })
	//     .catch(error => {
	//       console.error(error);
	//     });
	// }
	setEditNote() {
		this.isInEditNote = true;
	}
	canelEditNote() {
		this.isInEditNote = false;
		this.note = this.cart.notes;
	}
	confirmEditNote() {
		var instance = this;
		instance.isSendingNote = true;
		axios
			.post<ShoppingCartModel>(`cart/setnotes`, {
				customerId: instance.getCurrentCustomerId,
				notes: this.note
			})
			.then(res => {
				instance.setCurrentCart(res.data);
				instance.note = instance.cart.notes;
				instance.$notify({
					title: "",
					text: "Le note sono state modificate",
					type: "success",
					group: "msg"
				});

				instance.isSendingNote = false;
				instance.isInEditNote = false;
			})
			.catch(error => {
				console.error(error);
				instance.isSendingNote = false;
				instance.canelEditNote();
			});
	}
	onAddressSelect(address: addressModel) {
		var instance = this;
		var isNew = address == null;
		instance.isChangingAddress = true;
		var text = isNew ? "L'indirizzo di spedizione è stato rimosso, ricordarsi di inserirlo manualmente nelle note dell'ordine" : "L'indirizzo di spedizione è stato impostato";
		if (isNew) address = new addressModel();
		axios
			.post<ShoppingCartModel>(`cart/setaddress/${this.getCurrentCustomerId}`, address)
			.then(res => {
				instance.setCurrentCart(res.data);
				instance.$notify({
					title: "",
					text: text,
					type: "success",
					group: "msg"
				});
				instance.isChangingAddress = false;
			})
			.catch(error => {
				console.error(error);
				instance.isChangingAddress = false;
			});

		instance.showAddressModal = false;
	}
	onComplete() {
		var instance = this;
		var mostraAlert = (instance.cart.products || []).some(x => {
			var p = x || new CartProductModel();
			return instance.isNoteRichieste(p.product || new productModel()) && (p.code2 == null || p.code2.length === 0 || p.code2.trim() == "");
		});
		if (mostraAlert) {
			this.$bvModal
				.msgBoxConfirm("Non tutte le note dei prodotti che le necessitano sono state inserite. Inviare comunque l'ordine?", {
					contentClass: "border-warning",
					headerBgVariant: "warning",
					title: "Attenzione",
					size: "md",
					buttonSize: "sm",
					okVariant: "warning",
					okTitle: "SI",
					cancelTitle: "NO",
					hideHeaderClose: false,
					centered: true
				})
				.then(value => {
					if (value == true) {
						instance.completaOrdine();
					} else instance.showNoteStato = true;
				})
				.catch(err => {
					// An error occurred
				});
		} else {
			instance.completaOrdine();
		}
	}
	completaOrdine() {
		var instance = this;
		instance.isProcessingOrder = true;

		var note = "";
		if (instance.receveOrderStatus) {
			note += `Desidero ricevere via email notifiche sullo stato del mio ordine, referente:`;
			note += `\n${instance.referentOrderStatusName} ${instance.referentOrderStatusEmail}. `;
		}
		if (instance.receveDDT) {
			if (note != "") note += "\n\n";
			note += `Desidero ricevere via email notifiche sul DDT, referente:`;
			note += `\n${instance.referentOrderStatusName} ${instance.referentOrderStatusEmail}. `;
		}
		if (instance.receveFattura) {
			if (note != "") note += "\n\n";
			note += `Desidero ricevere via email notifiche sulla fattura, Referente:`;
			note += `\n ${instance.referentOrderStatusName} ${instance.referentOrderStatusEmail}. `;
		}
		if (note != "") note += "\n\n";
		note += instance.note || "";

		var request =
			// (note || "") != ""
			// 	?
			{
				customerId: instance.getCurrentCustomerId,
				notes: note,
				ReferenceCode: instance.customerReferentCode
			};
		// : null

		axios
			.post<sisasPlaceOrderResult>(`cart/compleate/${this.getCurrentCustomerId}`, request)
			.then(res => {
				if (res != null && res.data.success) {
					// instance.setCurrentCart(res.data.cart);
					// instance.isOrderCreated = true;
					// instance.$notify({
					//   title: "",
					//   text: "L'indirizzo di spedizione è stato impostato",
					//   type: "success",
					//   group: "msg"
					// });

					instance.setCurrentCart(res.data.cart);
					instance.isProcessingOrder = false;
					// instance.$router.push({ path: `/order/${res.data.createdOrder.id}` });
					instance.$router.push({ path: `/thankyou/${res.data.createdOrder.id}` });
				} else {
					(instance as any).$notify({
						group: "msg",
						type: "error",
						title: "Invio dell'ordine non riuscito",
						text: "Riprovare più tardi o contattare il servizio clienti"
					});
					instance.setCurrentCart(res.data.cart);
					instance.isProcessingOrder = false;
				}
			})
			.catch(error => {
				console.error(error);
				instance.isProcessingOrder = false;
			});
	}
	created() {
		var instance = this;
		if (instance.cart == null || instance.cart.products == null || instance.cart.products.length == 0) instance.$router.replace({ name: "cart" });
		else {
			var setShippingInfo = false;
			instance.note = this.cart != null ? this.cart.notes || null : null;
			instance.deliveryDate = this.cart != null ? this.cart.deliveryDate : null;
			if (this.cart != null && this.cart.shippingsType) {
				instance.shippingsType = this.cart.shippingsType;
			} else {
				instance.shippingsType = "Standard";
				setShippingInfo = true;
			}
			if (setShippingInfo) this.setShippingValue();
			instance.fetchListini();
		}
	}
	get standardDate(): Date {
		var d = moment()
			.businessAdd(this.standardDays)
			.toDate();
		return d;
	}
	get standardDateString(): string {
		return moment(this.standardDate).format("DD MMM YYYY");
	}
	get expressDate(): Date {
		var d = moment()
			.businessAdd(this.expressDays)
			.toDate();
		return d;
	}
	get expressDateString(): string {
		return moment(this.expressDate).format("DD MMM YYYY");
	}
	fetchListini() {
		var instance = this;
		instance.isLoadingLisini = true;
		axios
			.post<sisasListiniModel[]>("/product/listini", {
				customerId: instance.getCurrentCustomerId,
				skus: instance.skus
			})
			.then(res => {
				instance.listini = res.data ? res.data : [];
				instance.isLoadingLisini = false;
			})
			.catch(error => {
				console.error(error);
				instance.isLoading = false;
			});
	}
	onShippingsTypeChange(shippingsType: string) {
		this.shippingsType = shippingsType;
		this.setShippingValue();
	}
	onDeliveryDateChange(date) {
		this.deliveryDate = date;
		this.setShippingValue();
	}
	setShippingValue() {
		var instance = this;
		instance.isSendingNote = true;
		axios
			.post<ShoppingCartModel>(`cart/setshippingvalue/${this.getCurrentCustomerId}`, {
				shippingsType: instance.shippingsType,
				deliveryDate: instance.deliveryDate
			})
			.then(res => {
				instance.setCurrentCart(res.data);

				instance.shippingsType = instance.cart.shippingsType;
				instance.deliveryDate = instance.cart.deliveryDate;
				// instance.$notify({
				//   title: "",
				//   text: "Le note sono state modificate",
				//   type: "success",
				//   group: "msg"
				// });

				// instance.isSendingNote = false;
				// instance.isInEditNote = false;
			})
			.catch(error => {
				console.error(error);
				// instance.isSendingNote = false;
				// instance.canelEditNote();
			});
	}
}
